<template>
  <a-modal
    v-model:visible="howToVisible"
    :width="600"
    class="how-to-modal"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :footer="null"
  >
    <template #title
      ><div class="title">
        How to enter keyword in username or page name type
      </div></template
    >
    <p class="gray-600">
      You can enter keyword with username or page name in these following
      channel’s formats:
    </p>

    <table class="table table-strip">
      <thead>
        <tr>
          <th width="140px">Channel</th>
          <th>Supported Format</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="icon-text">
            <font-awesome-icon :icon="faFacebook" class="icon-facebook" />
            <span>Facebook</span>
          </td>
          <td>
            <strong class="gray-800">Page Name</strong>: This is the name that
            appears on the Facebook page. You'll typically see the page name
            prominently displayed at the top of the page.
          </td>
        </tr>

        <tr>
          <td class="icon-text">
            <font-awesome-icon :icon="faXTwitter" class="icon-twitter" />
            <span>X (Twitter)</span>
          </td>
          <td>
            <strong class="gray-800">Username:</strong> This is a unique
            identifier that appears in X (Twitter) URL (x.com/username or
            twitter.com/username) and is preceded by the "@" symbol.
          </td>
        </tr>

        <tr>
          <td class="icon-text">
            <font-awesome-icon :icon="faInstagram" class="icon-instagram" />
            <span>Instagram</span>
          </td>
          <td>
            <strong class="gray-800">Username:</strong> This is a unique
            identifier that appears in Instagram URL (instagram.com/username)
            and is preceded by the "@" symbol.
          </td>
        </tr>

        <tr>
          <td class="icon-text">
            <font-awesome-icon :icon="faYoutube" class="icon-youtube" />
            <span>Youtube</span>
          </td>
          <td>
            <strong class="gray-800">Channel Name:</strong> This is the name
            that appears on the channel page. You'll typically see the channel
            name prominently displayed at the top of the page.
          </td>
        </tr>

        <tr>
          <td class="icon-text">
            <font-awesome-icon :icon="faTiktok" class="icon-tiktok" />
            <span>TikTok</span>
          </td>
          <td>
            <strong class="gray-800">Username:</strong> This is a unique
            identifier that appears in TikTok URL (tiktok.com/username) and is
            preceded by the "@" symbol.
          </td>
        </tr>
      </tbody>
    </table>
  </a-modal>
</template>
<script>
import {
  faXTwitter,
  faFacebook,
  faYoutube,
  faInstagram,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

import { ref } from 'vue';
export default {
  name: 'KeywordHowToModal',
  setup() {
    const howToVisible = ref(false);
    const closeHowTo = () => {
      howToVisible.value = false;
    };
    return {
      howToVisible,
      closeHowTo,
      faFacebook,
      faYoutube,
      faXTwitter,
      faInstagram,
      faTiktok,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  font-weight: 500;
  color: #272b41;
}
.icon-text {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.icon-facebook {
  background-color: #f3f8fe;
  border-color: #1877f2 !important;
  color: #1877f2 !important;
}
.icon-twitter {
  background-color: #f4fafe;
  border-color: #000000 !important;
  color: #000000 !important;
}
.icon-instagram {
  background-color: #fdf4f8;
  border-color: #dd2a7b !important;
  color: #dd2a7b !important;
}
.icon-youtube {
  background-color: #fef4f3;
  border-color: #e32212 !important;
  color: #e32212 !important;
}
.icon-blockdit {
  background-color: #f6f8ff;
  border-color: #4a69ff !important;
  color: #4a69ff !important;
}

.table {
  thead,
  tbody {
    td,
    th {
      padding: 0.75rem 0px 0.75rem 1rem;
    }
    th {
      background-color: #f4f5f7;
      color: #272b41;
    }
    td {
      color: #5a5f7d;
    }
  }
}

.table-strip {
  tbody {
    tr:not(:last-child) {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: #e7e8ee;
    }
  }
}

.gray-600 {
  color: #5a5f7d;
}
.gray-800 {
  color: #272b41;
}
</style>
