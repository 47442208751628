<template>
  <div
    class="keyword-input-block"
    :class="{
      'has-error': duplicateError.result,
      'no-padding': noPadding,
      'category-keyword-mode': hasSentiment,
    }"
  >
    <div v-if="hasTitle" class="block-title">
      <KeywordHowToModal v-model:visible="howToVisible"></KeywordHowToModal>
      <div v-if="title === 'Enter Exclude Keyword'">
        <div>
          <img :src="require('@/assets/images/icon/exclude-keyword.svg')" />
          {{ title.split(' ')[0] }}
          <span class="span-exclude">
            {{ title.split(' ')[1] }}
          </span>
          {{ title.split(' ')[2] }}
        </div>

        <div class="sub-title mt-2 flex-items-center">
          <span
            >You can enter keyword(s) with words, phases, hashtag, username, or
            page name and use a comma or press the enter to separate
            keywords.</span
          >
          <a
            class="learn-more flex-items-center ml-1"
            @click.prevent="howToVisible = true"
          >
            Learn more
            <FeatherIcon
              type="help-circle"
              class="mb-1 ml-1"
              :size="16"
            ></FeatherIcon
          ></a>
        </div>
      </div>
      <div v-else>
        <div>
          <img :src="require('@/assets/images/icon/keyword.svg')" /> {{ title }}
        </div>

        <div class="sub-title mt-2 flex-items-center">
          <span
            >You can enter keyword(s) with words, phases, hashtag, username, or
            page name and use a comma or press the enter to separate
            keywords.</span
          >
          <a
            class="learn-more flex-items-center ml-1"
            @click.prevent="howToVisible = true"
          >
            Learn more
            <FeatherIcon
              type="help-circle"
              class="mb-1 ml-1"
              :size="16"
            ></FeatherIcon
          ></a>
        </div>
      </div>
    </div>
    <div class="block-row">
      <div
        id="keyword-mode-selector"
        class="select-mode"
        :class="{ 'category-select-mode': hasSentiment }"
      >
        <a-select
          v-model:value="inputMode"
          :getPopupContainer="(trigger) => trigger.parentNode"
          class="mode-selector"
          @change="onChangeInputMode"
        >
          <a-select-option value="word"> Word or Phrase </a-select-option>
          <a-select-option value="hashtag"> Hashtag </a-select-option>
          <a-select-option value="username">
            Username or Page name
          </a-select-option>
        </a-select>
      </div>
      <div
        id="keyword-input"
        class="word-input-wrapper"
        :class="{ 'has-error': duplicateError.result }"
      >
        <Chips
          :modelValue="wordList"
          :getPopupContainer="(trigger) => trigger.parentNode"
          separator=","
          class="word-input-selector"
          :placeholder="
            wordList.length === 0
              ? 'Separate words and phrases with a comma or by pressing enter'
              : ''
          "
          @add="onKeywordChange"
          @remove="onKeywordRemove"
        >
          <!--          <template v-slot:removetokenicon>-->
          <!--            <div class="p-icon p-chips-token-icon" aria-hidden="true" data-pc-section="removetokenicon">-->
          <!--            </div>-->
          <!--          </template>-->
        </Chips>
        <!-- <a-select
          ref="keywordInputRef"
          v-model:value="wordList"
          :getPopupContainer="(trigger) => trigger.parentNode"
          :token-separators="[',']"
          class="word-input-selector"
          dropdown-class-name="no-dropdown"
          mode="tags"
          style="width: 100%"
          placeholder="Separate words and phrases with a comma or by pressing enter"
          @change="onKeywordChange"
          @inputKeyDown="onInputKeydown"
        >
        </a-select> -->
        <div v-if="duplicateError.result" class="error-dup-text">
          Duplicate Keyword
        </div>
      </div>
      <div class="category-action-mobile">
        <a-dropdown
          v-if="hasSentiment"
          :trigger="['click']"
          :get-popup-container="(trigger) => trigger.parentNode"
        >
          <a class="sentiment-dropdown" @click.prevent>
            <FeatherIcon
              :type="sentimentIcon"
              size="24"
              :color="sentimentColor"
            />
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu @click="onSentimentClick">
              <a-menu-item key="0" value="5"
                ><FeatherIcon type="smile" size="24" color="#20c997" />
                <span class="sentiment-text">Positive</span>
              </a-menu-item>
              <a-menu-item key="1" value="0"
                ><FeatherIcon type="meh" size="24" color="#fa8b0c" />
                <span class="sentiment-text">Neutral</span>
              </a-menu-item>
              <a-menu-item key="2" value="-5"
                ><FeatherIcon type="frown" size="24" color="#ff4d4f" />
                <span class="sentiment-text">Negative</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <div class="add-button-wrapper">
          <a-button
            v-if="title === 'Enter Exclude Keyword'"
            class="exclude-button"
            :type="duplicateError.result ? 'ghost' : 'primary'"
            :disabled="duplicateError.result"
            @click="addKeywords"
          >
            Exclude
          </a-button>
          <a-button
            v-else
            class="add-button"
            :type="duplicateError.result ? 'ghost' : 'primary'"
            :disabled="duplicateError.result"
            @click="addKeywords"
          >
            Add
          </a-button>
        </div>
      </div>
      <div class="category-action-desktop">
        <a-dropdown
          v-if="hasSentiment"
          :trigger="['click']"
          :get-popup-container="(trigger) => trigger.parentNode"
        >
          <a class="sentiment-dropdown" @click.prevent>
            <FeatherIcon
              :type="sentimentIcon"
              size="24"
              :color="sentimentColor"
            />
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu @click="onSentimentClick">
              <a-menu-item key="0" value="5"
                ><FeatherIcon type="smile" size="24" color="#20c997" />
                <span class="sentiment-text">Positive</span>
              </a-menu-item>
              <a-menu-item key="1" value="0"
                ><FeatherIcon type="meh" size="24" color="#fa8b0c" />
                <span class="sentiment-text">Neutral</span>
              </a-menu-item>
              <a-menu-item key="2" value="-5"
                ><FeatherIcon type="frown" size="24" color="#ff4d4f" />
                <span class="sentiment-text">Negative</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <div class="add-button-wrapper">
          <a-button
            v-if="title === 'Enter Exclude Keyword'"
            class="exclude-button"
            :type="duplicateError.result ? 'ghost' : 'primary'"
            :disabled="duplicateError.result"
            @click="addKeywords"
          >
            Exclude
          </a-button>
          <a-button
            v-else
            class="add-button"
            :type="duplicateError.result ? 'ghost' : 'primary'"
            :disabled="duplicateError.result"
            @click="addKeywords"
          >
            Add
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed } from 'vue';
import { DownOutlined } from '@ant-design/icons-vue';
import KeywordHowToModal from './KeywordHowToModal.vue';
import helper from '@/services/helper';

/* eslint-disable no-unused-vars */
export default {
  components: {
    DownOutlined,
    KeywordHowToModal,
  },
  props: {
    hasSentiment: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Enter Keyword',
    },
    currentWordList: {
      type: Array,
      default: () => [],
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add'],
  setup(props, { emit }) {
    const keywordInputRef = null;
    const wordList = ref([]);
    const inputMode = ref('word');
    const { currentWordList } = toRefs(props);
    const howToVisible = ref(false);

    const onChangeInputMode = () => {
      // console.log('CHANGE', inputMode.value);
    };

    const sentimentValue = ref(0);
    const sentimentIcon = computed(() => {
      if (sentimentValue.value < -1) {
        return 'frown';
      } else if (sentimentValue.value > 1) {
        return 'smile';
      } else {
        return 'meh';
      }
    });
    const sentimentColor = computed(() => {
      if (sentimentValue.value < -1) {
        return '#ff4d4f';
      } else if (sentimentValue.value > 1) {
        return '#20c997';
      } else {
        return '#fa8b0c';
      }
    });

    const onSentimentClick = (e) => {
      sentimentValue.value = parseInt(e.item.value);
    };

    const isDuplicateWord = (word) => {
      const kw = convertKeyword(word);
      if (currentWordList.value) {
        const filteredList = currentWordList.value
          .filter((obj) => obj.type === inputMode.value)
          .map((obj) => obj.keyword);
        return filteredList.includes(kw);
      }
      return false;
    };

    const duplicateError = computed(() => {
      // const foundDup = {
      //   result: false,
      //   wordList: [],
      // };
      // for (let i in wordList.value) {
      //   if (isDuplicateWord(wordList.value[i])) {
      //     foundDup.result = true;
      //     foundDup.wordList.push(wordList.value[i]);
      //   }
      // }
      // return foundDup;
      return {
        result: false,
        wordList: [],
      };
    });

    const addKeywords = () => {
      // keywordInputRef.value.blur();
      setTimeout(() => {
        if (duplicateError.value && duplicateError.value.result) {
          return false;
        } else {
          const list = JSON.parse(JSON.stringify(wordList.value));
          if (list && list.length > 0) {
            emit('add', {
              mode: inputMode.value,
              list,
              sentimentValue: sentimentValue.value,
            });
            // clear list
            // console.log(list);

            wordList.value = [];
          }
        }
      }, 350);
    };

    const onInputKeydown = (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        setTimeout(() => {
          keywordInputRef.value.blur();
        });
        // setTimeout(() => {
        //   keywordInputRef.value.focus();
        // }, 500);
      }
    };

    const onKeywordChange = (e) => {
      const value = e.value;
      if (value.length >= 1) {
        for (let word of value) {
          const wordNoExceptChar = helper.replaceExpectChar(word)
          if (!wordList.value.includes(wordNoExceptChar)) {
            wordList.value.push(wordNoExceptChar);
          }
        }
      } else if (value.length === 0) {
        e.value = '';
      }
    };

    const onKeywordRemove = (e) => {
      const value = e.value[0];
      if (wordList.value.includes(value)) {
        const index = wordList.value.findIndex((w) => w === value);
        wordList.value.splice(index, 1);
      }
    };

    const convertKeyword = (word) => {
      if (inputMode.value === 'hashtag') {
        if (word.charAt(0) !== '#') {
          return `#${word}`;
        }
      }
      if (inputMode.value === 'username') {
        if (word.charAt(0) !== '@') {
          return `@${word}`;
        }
      }
      if (inputMode.value === 'word') {
        if (word.charAt(0) === '@' || word.charAt(0) === '#') {
          return word.slice(1);
        }
      }
      return word;
    };

    return {
      keywordInputRef,
      inputMode,
      wordList,
      sentimentIcon,
      sentimentColor,
      onSentimentClick,
      onChangeInputMode,
      duplicateError,
      addKeywords,
      onInputKeydown,
      onKeywordChange,
      onKeywordRemove,
      howToVisible,
    };
  },
};
</script>

<style lang="scss">
#keyword-input {
  .ant-select-selector {
    border-radius: 4px !important;
    height: 48px !important;
  }
}
#keyword-type-selector,
#keyword-mode-selector {
  .ant-select-selector .ant-select-selection-item {
    line-height: 48px !important;
  }
  @media (max-width: 560px) {
    flex: 1 1 auto;
    max-width: 100%;
  }
}
#keyword-input {
  position: relative;
  &.has-error {
    .ant-select-selector {
      border-color: #f5222d !important;
    }
  }
  .error-dup-text {
    position: absolute;
    left: 0;
    bottom: -22px;
    font-size: 13px;
    color: #f5222d;
  }
}
.keyword-input-block {
  padding: 25px;
  &.has-error {
    padding: 25px 25px 48px 25px;
  }
  &.no-padding {
    padding: 0;
    &.has-error {
      padding: 0 0 12px 0;
    }
  }
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  .block-title {
    font-size: 16px;
    font-weight: 500;
    color: #272b41;
    margin-bottom: 16px;
    .span-exclude {
      color: #fa8b0c;
    }
  }
  .block-row {
    display: flex;
    column-gap: 16px;
    row-gap: 8px;
    .select-mode {
      min-width: 200px;
      flex: 0 1 auto;
    }
    .category-select-mode {
      min-width: 100px;
    }
    .word-input-wrapper {
      flex: 1 1 auto;
    }
    .ant-dropdown {
      width: 170px;
      .ant-dropdown-menu {
        padding: 15px 0;
        .ant-dropdown-menu-title-content {
          display: flex;
          align-items: center;

          .sentiment-text {
            margin-left: 10px;
          }
        }
      }
    }
    .category-action-mobile {
      display: none;
    }
    .category-action-desktop {
      display: flex;
      column-gap: 16px;
      row-gap: 8px;
    }
  }
  .add-button-wrapper {
    margin-left: auto;
    .add-button {
      height: 48px;
    }
    .exclude-button {
      height: 48px;
      background-color: #fa8b0c;
    }
  }

  .sentiment-dropdown {
    border: 1px solid #e3e6ef;
    padding: 10px;
    border-radius: 4px;
    color: #5a5f7d;
    display: flex;
    align-items: center;
    .anticon {
      margin-left: 8px;
    }
  }
}
.word-input-selector {
  width: 100%;
  min-height: 48px;
  max-height: 120px;
  .p-inputtext {
    width: 100%;
    overflow-y: auto;
    padding: 12px 11px;
    .p-chips-input-token {
      padding: 0;
      input {
        font-size: 14px;
        line-height: 24px;
      }
      input::placeholder {
        color: #9299b8;
      }
    }
    .p-chips-token {
      height: 24px;
      padding-inline-start: 8px;
      padding-inline-end: 4px;
      background: #eff0f3;
      border-radius: 4px;
      margin-bottom: 4px;
      .p-chips-token-label {
        font-size: 14px;
        line-height: 22px;
      }
      .p-chips-token-icon {
        font-size: 10px;
        line-height: 1;
      }
    }
  }
  // .ant-select-selector {
  //   overflow: auto;
  // }
}
.mode-selector {
  width: 100%;
  min-width: 160px;
  //    .ant-select-selector {
  //      height: 48px !important;
  //    }
  //   .ant-select-single .ant-select-selector .ant-select-selection-item {
  //     line-height: 48px !important;
  //   }
}
@media screen and (max-width: 1230px) {
  .category-keyword-mode {
    .block-row {
      display: block;
      .category-select-mode,
      .word-input-wrapper,
      .sentiment-dropdown {
        margin-bottom: 16px;
      }
      .category-action-mobile {
        display: flex;
        justify-content: space-between;
        .sentiment-dropdown {
          max-width: 66px;
        }
      }
      .category-action-desktop {
        display: none !important;
      }
    }
  }
}
@media screen and (max-width: 830px) {
  .keyword-input-block {
    .block-row {
      display: block;
    }
  }

  #keyword-type-selector,
  #keyword-mode-selector,
  #keyword-input {
    margin-bottom: 16px;
  }
}
</style>
<style scoped>
.sub-title {
  color: #272b41;
  font-size: 13px;
}
.learn-more {
  color: #3371ff;
}
.flex-items-center {
  display: flex;
  align-items: center;
}
:deep(.p-chips-token-icon) > g > path {
  color: rgb(146, 153, 184);
}
:deep(.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container) {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
:deep(#keyword-mode-selector .ant-select-selector) {
  height: 100% !important;
  min-height: 48px;
}
</style>
